import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as API from 'redux/api';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Title from 'components/Shared/Title';
import { get, isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import {
  isOnlyTextValid,
  isCreditCardNumberValid,
  isCardCodeValid,
  isExpirationDateValid,
  isPostalCodeValid,
  creditCardNumbers,
  getCardCodeSize,
} from 'utils/validations';
import { TextField, Checkbox } from 'components/FormFields';
import SplashScreen from 'components/Shared/SplashScreen';
import OfferBox from 'components/Shared/OfferBox';
import SummaryBox from 'components/Shared/SummaryBox';
import ScrollToTop from 'components/App/ScrollToTop.js';
import { CardElement } from '@stripe/react-stripe-js';
import classNames from 'classnames/bind';
import { removePhoneCountryCode, usingStripe } from 'utils/helper.js';
import * as ROUTES from 'utils/constants.js';
import HelpIcon from '@material-ui/icons/ErrorOutline';
import NoteIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';

const paymentIntentErrorMessage = `Sorry, there was an error. Please try again later or contact with support`;

const Payment = ({
  classes,
  actions,
  filter,
  marina,
  marinaName,
  selectedOffer,
  selectedExtra,
  selectedExtraPrice,
  selectedExtraTax,
  paymentDetails,
  contactDetails,
  isLoading,
  selectedOfferProperties,
  clientSecret,
}) => {
  const handleResetError = useCallback(() => {
    actions.add({ bookingReference: '' });
    actions.add({ error: '' });
  }, [actions]);

  useEffect(() => {
    handleResetError();
  }, [handleResetError]);

  const cx = classNames.bind(classes);
  const isUsingStripe = usingStripe(get(marina, 'data.MoloOnlineBookingPaymentsProvider'));
  const [error, setError] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c72223',
        iconColor: '#fa755a',
      },
    },
  };
  useEffect(() => {
    !get(paymentDetails, 'cardName') && actions.add({ cardName: { value: '', required: true } });
    !get(paymentDetails, 'cardNumber') &&
      actions.add({
        cardNumber: {
          value: '',
          required: !isUsingStripe,
          isValid: isUsingStripe,
        },
      });
    !get(paymentDetails, 'ccv') &&
      actions.add({
        ccv: {
          value: '',
          required: !isUsingStripe,
          isValid: isUsingStripe,
        },
      });
    !get(paymentDetails, 'expiryDate') &&
      actions.add({
        expiryDate: {
          value: '',
          required: !isUsingStripe,
          isValid: isUsingStripe,
        },
      });
    !get(paymentDetails, 'postalCode') &&
      actions.add({
        postalCode: {
          value: '',
          required: !isUsingStripe,
          isValid: isUsingStripe,
        },
      });
    !get(paymentDetails, 'condition1') && actions.add({ condition1: { value: false, required: true } });
    !get(paymentDetails, 'condition2') && actions.add({ condition2: { value: false, required: true } });
    !get(paymentDetails, 'paymentAuthorization') &&
      actions.add({ paymentAuthorization: { value: false, required: false } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isUsingStripe) {
      actions.add({
        cardName: {
          value: 'Stripe',
          isValid: !!(cardComplete && !error),
          required: true,
        },
      });
    }
  }, [actions, cardComplete, error, isUsingStripe]);

  const DEFAULT_MINIMUM_PAYMENT = 1;

  useEffect(() => {
    if (isUsingStripe && !clientSecret) {
      try {
        actions.add({ loading: true });
        const contactData = Object.keys(contactDetails).reduce(
          (acc, item) => ({ ...acc, [item]: contactDetails[item].value || '' }),
          {}
        );

        const { homePhone, mobilePhone, MobilePhoneCountryCode, HomePhoneCountryCode } = contactData;

        contactData.homePhone = removePhoneCountryCode(homePhone, HomePhoneCountryCode);
        contactData.mobilePhone = removePhoneCountryCode(mobilePhone, MobilePhoneCountryCode);

        axios
          .post(API.paymentIntent, {
            amount: get(selectedOffer, 'minimumPayment') || DEFAULT_MINIMUM_PAYMENT,
            marinaId: get(marina, 'data.MarinaId'),
            ...contactData,
          })
          .then((res) => {
            actions.add({ customer: res.data.customerId });
            actions.add({ clientSecret: res.data.secret });
            actions.add({ loading: false });
          })
          .catch((error) => {
            actions.add({ loading: false });
            setDisabled(true);
            actions.add({ bookingReference: 'error' });
            actions.add({ error: paymentIntentErrorMessage });
          });
      } catch (error) {
        actions.add({ loading: false });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = ({ id, value, isValid, required }) => {
    actions.add({ [id]: { value, isValid, required } });
    if (id === 'cardNumber' && !isUsingStripe) {
      actions.add({
        ccv: {
          value: get(paymentDetails, 'ccv.value'),
          isValid: isCardCodeValid(
            get(paymentDetails, 'ccv.value'),
            getCardCodeSize(get(paymentDetails, 'cardNumber.value', ''))
          ),
          required: true,
        },
      });
      actions.add({ bookingReference: '' });
    }
  };

  const getOfferSelectedPrice = () => {
    if (selectedOffer.type === ROUTES.OFFER_TYPES.DOCKAGE) {
      return selectedOffer.discountedAverageNightlyRate
        ? selectedOffer.discountedAverageNightlyRate
        : selectedOffer.averageNightlyRate;
    }

    if (selectedOffer.type === ROUTES.OFFER_TYPES.RESOURCE) {
      return selectedOffer.standardNightlyRate;
    }
  };

  const getFilterTitle = () => {
    if (selectedOffer.type === ROUTES.OFFER_TYPES.DOCKAGE) {
      return `${filter.type.value} Dockage`;
    }

    if (selectedOffer.type === ROUTES.OFFER_TYPES.RESOURCE) {
      return `${selectedOffer.offerType} Resource`;
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete='off'>
      {!isEmpty(get(paymentDetails, 'bookingReference', 'error')) &&
        get(paymentDetails, 'bookingReference', 'error') !== 'error' && <Redirect to={ROUTES.BOOKING_COMPLETE} />}
      {get(paymentDetails, 'bookingReference') === 'error' && (
        <>
          <ScrollToTop />
          <div className={classes.submittedWithError}>
            <Typography variant='h6' className={classes.error}>
              {get(paymentDetails, 'error')}
            </Typography>
          </div>
        </>
      )}
      {isLoading && (
        <div className={classes.overlay}>
          <ScrollToTop />
          <SplashScreen animation='lighthouse' />
        </div>
      )}
      <div className={cx({ loading: isLoading })}>
        <Paper className={classes.paper} elevation={1}>
          <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
            {!usingStripe(get(marina, 'data.MoloOnlineBookingPaymentsProvider')) ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='cardName'
                    label='Cardholder Name'
                    value={get(paymentDetails, 'cardName.value', '')}
                    submitValue={(e) => handleChange(e)}
                    onInput={(e) => {
                      e.target.value = isOnlyTextValid(e.target.value);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='cardNumber'
                    label='Card Number'
                    value={get(paymentDetails, 'cardNumber.value', '')}
                    submitValue={(e) => handleChange(e)}
                    onInput={(e) => {
                      e.target.value = creditCardNumbers(e.target.value);
                    }}
                    validate={(v) => isCreditCardNumberValid(v)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id='ccv'
                    label='CCV'
                    value={get(paymentDetails, 'ccv.value', '')}
                    submitValue={(e) => handleChange(e)}
                    min={0}
                    onInput={(e) => {
                      const inputValue = e.target.value.match(/[0-9]+/g);
                      e.target.value =
                        inputValue &&
                        inputValue[0].slice(0, getCardCodeSize(get(paymentDetails, 'cardNumber.value', '')));
                    }}
                    validate={(v) => isCardCodeValid(v, getCardCodeSize(get(paymentDetails, 'cardNumber.value', '')))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id='expiryDate'
                    label='Expiry Date'
                    placeholder='mm/yy'
                    value={get(paymentDetails, 'expiryDate.value', '')}
                    submitValue={(e) => handleChange(e)}
                    validate={(v) => isExpirationDateValid(v)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id='postalCode'
                    label='Postal Code'
                    value={get(paymentDetails, 'postalCode.value', '')}
                    submitValue={(e) => handleChange(e)}
                    validate={(v) => isPostalCodeValid(v)}
                    required
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                className={cx('stripe', {
                  borderNotError: !error || cardComplete,
                })}>
                <label htmlFor='cardElement'>Credit or debit card</label>
                <CardElement
                  onFocus={handleResetError}
                  options={{ ...CARD_ELEMENT_OPTIONS, disabled }}
                  onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);
                    handleChange({
                      id: 'cardElement',
                      isValid: e.complete,
                      required: true,
                    });
                  }}
                />
                <div className={classes.cardError} role='alert'>
                  {get(error, 'message')}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Checkbox
                id='condition1'
                variant='body1'
                checked={get(paymentDetails, 'condition1.value', false)}
                label={`By checking this box, I agree to the terms and conditions of this reservation at ${marinaName}.`}
                value='condition1'
                submitValue={(e) => handleChange(e)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                id='condition2'
                variant='body1'
                checked={get(paymentDetails, 'condition2.value', false)}
                label={
                  <div>
                    <span>By checking this box, I agree to the Terms of </span>
                    <a target='_blank' rel='noopener noreferrer' href={'https://getmolo.com/legal/'}>
                      Molo Online Booking
                    </a>
                  </div>
                }
                value='condition2'
                submitValue={(e) => handleChange(e)}
                required
              />
            </Grid>
          </Grid>
        </Paper>
        <Title currentPage={{ value: 'Notes to Marina' }} customIcon={NoteIcon} />
        <Paper className={classes.paper} elevation={1}>
          <TextField
            id='marina-note'
            label='Note'
            placeholder='Leave a Note to the Marina'
            multiline
            rowsMax={7}
            submitValue={(e) => actions.addToOffer({ userNote: e.value })}
            inputProps={{ maxLength: 400 }}
          />
        </Paper>
        <Title currentPage={{ value: 'Offer Selected' }} customIcon={LocalOfferIcon} />
        <OfferBox
          id={selectedOffer.offerID}
          key={selectedOffer.offerID}
          title={selectedOffer.customerOfferName}
          description={selectedOffer.customerDescription}
          price={getOfferSelectedPrice()}
          priceMsg={selectedOffer.priceMethod}
          extras={selectedExtra}
          showCalendar={false}
        />
        <Title currentPage={{ url: '/offers', value: 'Booking Summary' }} />
        <SummaryBox
          filterTitle={getFilterTitle()}
          filterArrival={filter.arrival.value}
          filterDeparture={filter.departure.value}
          extras={selectedExtra}
          selectedExtraPrice={parseFloat(selectedExtraPrice, 10)}
          selectedExtraTax={parseFloat(selectedExtraTax, 10)}
          offer={selectedOffer}
        />
        {(get(selectedOffer, 'termsAndConditions') ||
          get(selectedOffer, 'Terms') ||
          get(selectedOfferProperties, 'onlineTerms')) && (
          <>
            <Title
              currentPage={{
                icon: ROUTES.PAYMENT,
                value: 'Terms and Conditions',
              }}
            />
            <Paper className={classes.paper} elevation={1}>
              <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
                <Grid item xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedOffer.termsAndConditions || selectedOffer.Terms || selectedOfferProperties.onlineTerms,
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {get(selectedOffer, 'securityDeposit') && (
          <>
            <Title
              currentPage={{
                icon: ROUTES.PAYMENT,
                value: 'Amount Due Today',
              }}
            />
            <Paper className={classes.paper} elevation={1}>
              <Grid container direction='row' justify='space-between' alignItems='center' spacing={4}>
                <Box display='flex'>
                  <Typography variant='body1'>Security Deposit</Typography>
                  <Tooltip
                    title='This deposit is to cover any potential damages or incidents that occur with your booking. The refund of this security deposit is outlined in the terms and conditions listed below.'
                    aria-label='help'
                    arrow
                    placement='top'>
                    <HelpIcon
                      style={{
                        marginLeft: 6,
                        transform: 'rotateX(180deg)',
                      }}
                    />
                  </Tooltip>
                </Box>
                <span className={classes.price}>$ {get(selectedOffer, 'securityDeposit')}</span>
              </Grid>
            </Paper>
          </>
        )}
        <Title currentPage={{ icon: ROUTES.PAYMENT, value: 'Payment Authorization' }} />
        <Paper className={classes.paper} elevation={1}>
          <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
            <Grid item xs={12}>
              <Typography variant='body1'>
                I authorize my card listed above to be charged automatically as outlined in the Billing Schedule for my
                marina fees above effective immediately. This payment authorization is valid and remain in effect unless
                I {get(contactDetails, 'firstName.value', 'N/A')} {get(contactDetails, 'lastName.value', 'N/A')}, notify
                marina of its cancellation by sending written notice or email.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                id='paymentAuthorization'
                checked={get(paymentDetails, 'paymentAuthorization.value', false)}
                label='I agree to the automatic billing terms.'
                value='paymentAuthorization'
                submitValue={(e) => handleChange(e)}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </form>
  );
};

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  marinaName: PropTypes.string.isRequired,
  marina: PropTypes.object.isRequired,
  selectedOffer: PropTypes.object.isRequired,
  selectedExtra: PropTypes.array,
  selectedExtraPrice: PropTypes.number,
  selectedExtraTax: PropTypes.number,
  contactDetails: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  clientSecret: PropTypes.string,
};

Payment.defaultProps = {
  selectedExtra: [],
  selectedExtraPrice: '',
  selectedExtraTax: '',
};

Payment.displayName = 'Payment';

export default Payment;
